<template>
  <div>
    <el-row style="padding:6px">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="系统版本">
          <el-select v-model="formInline.user" size="mini" @change="authorization">
            <!-- <el-option label="用户PC端" value="用户PC端"></el-option> -->
            <el-option label="用户微信端" value="用户微信端"></el-option>
            <el-option label="客户微信端" value="客户微信端"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="操作">
          <el-button size="mini" icon="el-icon-view" @click="expandAll">全部展开</el-button>
          <el-button size="mini" icon="el-icon-copy-document" @click="collapseAll">全部折叠</el-button>
          <el-button size="mini" icon="el-icon-circle-check" @click="allOnOff(1)">全开</el-button>
          <el-button size="mini" icon="el-icon-circle-close" @click="allOnOff(0)">全关</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <el-table
      v-loading="loding"
      :data="authorityData"
      :height="tableHeight"
      row-key="smId"
      border
      ref="theTable"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
    >
      <el-table-column
        prop="smMenuName"
        label="菜单名称"
        align="center"
      ></el-table-column>
      <el-table-column label="权限" align="center">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.smStatus"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-value="Number('1')"
            :inactive-value="Number('0')"
            @change="open($event, scope.row)"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="修改时间" align="center" sortable prop="smUpdateTime">
        <!-- <template slot-scope="scope" sortable>
          <i class="el-icon-time"></i>
          <span style="margin-left: 10px">{{ scope.row.smUpdateTime }}</span>
        </template> -->
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableHeight: window.innerHeight - 200,
      loding: false,
      authorityData: [],
      formInline: {
        user: "用户微信端",
      },
      charge:''
    };
  },
  created() {
    this.authorization();
  },
  methods: {
    open($event, row) {
      console.log($event, row.smStatus)
      this.charge = row.smStatus
      console.log(this.charge)
      let option = row.smStatus === 1?'启用':'关闭'
      this.$confirm(`此操作将${option}权限, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(()=>{
        this.loding = true
        return this.swichChange($event, row)
      }).
      then(() => {
        this.authorization()
        this.loding = false
        this.$message({
          type: 'success',
          message: `${option}权限成功!`
        });
      }).catch(() => {
        // console.log(row.swich,row.swich === 1 ? 1 : 0)
        row.smStatus = row.smStatus === 1 ? 0 : 1;
        console.log(233)
        this.authorization()
        this.loding = false
        this.$message({
          type: 'info',
          message: '已取消操作'
        });
      });
    },
    //授权保存
    authorSave(arr,type=this.charge){
      console.log(arr)
      return this.$axios({
        url: `/common1/menus/updateStatus`,
        method: "put",
        data: {
          smIds: arr,
          smStatus:type
        }
      })
        // .then(res => {
        //   this.$message.success("保存成功");
        //   this.authorityDia = false;
        //   this.getMenu()
        //   this.getAuthority()
        //   this.getPageAuth()
        // })
        // .catch(err => {
        //   this.isError(err)
        // });
    },
    getClientAuthorMenu() {
      this.loding = true
      return this.$axios({
        url: `/common1/menus/list`,
        method: "get",
        params: {
          smSystemVersion: this.formInline.user,
        },
      }).then((res) => {
        // return this.transDate(res.data.result, "smId", "smParentId");
        this.loding = false
        return res.data.result;
      }).catch(err => {
        this.loding= false
        console.log(err.response.data.msg)
        this.$message.error(err.response.data.msg);
      });
    },
    async authorization() {
      // this.clickrow = row
      let data = this.transDate(
        await this.getClientAuthorMenu(),
        "smId",
        "smParentId"
      );
      // this.$set(this.authorityData,data)
      console.log(data)
      this.authorityData = data
      console.log(this.authorityData)
      // this.authorityDia = true
    },
    setSwich(data,val){ //遍历树  获取id数组
      console.log(data)
      for(let item of data){
        // item.smStatus = val
        if(item.children){
          this.setSwich(item.children);
        }
      }
      console.log(data)
      return data
    },
    openKKK(item, i, parentId) {
        i++;
        for (const item1 of item) {
          if (item1.smId == parentId[i]) {
            item1.smStatus = 1
          }
        }
        if (i == parentId.length-1) {
            return;
        }
        for (const item2 of item.children) {
            this.openKKK(item2, i, parentId);
        }
    },
    swichChange(val, row) {
      let arr = JSON.stringify(this.setSwich([row],val))
      row = JSON.parse(arr.substring(1,arr.length-1))//QU []
      let ret = []
      let char = []
      char.push(row.smId)
      ret.push(row)
      let cb = this.treeTransArray(ret)
      cb.forEach(item => {
        console.log(item.smStatus)
        if(this.charge !== item.smStatus) {
          char.push(item.smId)
        }
      })
      console.log(char)
      return this.authorSave(char)
    },
    getParent(data2, nodeId2) {
        var arrRes = [];
        if (data2.length == 0) {
            if (!!nodeId2) {
                arrRes.unshift(data2)
            }
            return arrRes;
        }
        let rev = (data, nodeId) => {
            for (let i = 0, length = data.length; i < length; i++) {
                let node = data[i];
                if (node.smId == nodeId) {
                    arrRes.unshift(node.smId)
                    rev(data2, node.smParentId);
                    break;
                }
                else {
                    if (!!node.children) {
                        rev(node.children, nodeId);
                    }
                }
            }
            return arrRes;
        };
        arrRes = rev(data2, nodeId2);
        return arrRes;
    },
    //json数组转树结构
    transDate(list, idstr, pidstr) {
      let result = [];
      let temp = {};
      for (let i = 0; i < list.length; i++) {
        temp[list[i][idstr]] = list[i]; //将nodes数组转成对象类型
      }
      for (let j = 0; j < list.length; j++) {
        let tempVp = temp[list[j][pidstr]]; //获取每一个子对象的父对象
        if (tempVp) {
          //判断父对象是否存在，如果不存在直接将对象放到第一层
          if (!tempVp["children"]) tempVp["children"] = []; //如果父元素的nodes对象不存在，则创建数组
          tempVp["children"].push(list[j]); //将本对象压入父对象的nodes数组
        } else {
          result.push(list[j]); //将不存在父对象的对象直接放入一级目录
        }
      }
      console.log(result)
      return result;
    },
    //树结构返回普通json数组
    treeTransArray(nodes) {
      let r = [];
      if (Array.isArray(nodes)) {
        for (let i = 0, l = nodes.length; i < l; i++) {
          r.push(nodes[i]); // 取每项数据放入一个新数组
          if (
            Array.isArray(nodes[i]["children"]) &&
            nodes[i]["children"].length > 0
          )
            // 若存在children则递归调用，把数据拼接到新数组中，并且删除该children
            r = r.concat(this.treeTransArray(nodes[i]["children"]));
          delete nodes[i]["children"];
        }
      }
      return r;
    },
    //全部展开
    expandAll() {
      this.forArr(this.authorityData, true);
    },
    //全部折叠
    collapseAll() {
      this.forArr(this.authorityData, false);
    },
    forArr(arr, isExpand) {
      for (const i of arr) {
        if ("children" in i) {
          this.$refs.theTable.toggleRowExpansion(i, isExpand);
          this.forArr(i.children, isExpand);
        }
      }
    },
    allOnOff(state) {
      let data = this.treeTransArray(this.authorityData);
      console.log(data,state)
      let cb = []
      data.forEach(item => {
        if(item.smStatus !== state) {
          cb.push(item.smId)
        }
      })
      let type = state === 1?'开启':'关闭'
      if(cb.length === 0) {
        this.$message.error(`权限已全部${type},请勿重复操作`)
        return
      }
      console.log(cb)
      this.loding = true
      this.authorSave(cb,state).then(res => {
        this.authorization()
        this.loding = false
      }).catch(err => {
        this.loding= false
        console.log(err.response.data.msg)
        this.$message.error(err.response.data.msg);
      })
      // for (const item of data) {
      //   item.swich = state;
      // }
      // this.authorityData = this.transDate(data, "smId", "smParentId");
    }
  },
};
</script>

<style></style>
